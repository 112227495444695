<template>
  <div class="activity_item" @click.stop="onExternal(data)">
    <div v-if="data.status !== 0" :class="['activity_status', statusConfig[data.status].class]">{{
      statusConfig[data.status].text
    }}</div>
    <el-image :src="data.banner" class="activity_img">
      <div slot="error" class="image-slot">
        <img src="@/assets/images-1/default_img.png" alt="">
      </div>
    </el-image>
    <div class="apply_btn" v-if="isHasBtn">
      <el-button size="mini" :disabled="!(data.apply && data.status === 2)" type="primary" @click.stop="onEnroll">{{
        $t(`activity.applyNow`) }}</el-button>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    isHasBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusConfig: {
        1: {
          class: 'bg_yellow',
          text: this.$t(`activity.activityStatus1`),
        },
        2: {
          class: 'bg_green',
          text: this.$t(`activity.activityStatus2`),
        },
        3: {
          class: 'bg_gray',
          text: this.$t(`activity.activityStatus3`),
        },
      }
    }
  },
  methods: {
    onEnroll() {
      // fetchActivityAccountV2({
      //   activityId: props.data.id,
      // }).then((res) => {
      //   const data = {
      //     listObj: res,
      //     activityId: props.data.id,
      //   }
      //   openModal(true, data)
      // })
    },
    onExternal(data) {
      // const url = `${data.detailUrl.indexOf('?') > 0 ? data.detailUrl + '&' : data.detailUrl + '?'
      //   }token=${getToken}&country=${getUserInfo.residence}`
      // window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss">
.activity_item {
  background: $white;
  border: 1px solid #F3F4FE;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  box-shadow: 0px 0px 8px 1px rgba(122, 134, 153, 0.15);
  transition: all 0.3s linear;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.2);
    transform: translate3d(0, -8px, 0);
  }

  .activity_status {
    font-size: 12px;
    padding: 2px 4px 2px 8px;
    position: absolute;
    top: 8px;
    @include rtl-sass-prop(right, left, 0);
    border-radius: 8px 0 0 8px;
    z-index: 3;
  }

  .bg_green {
    background-color: #bbebc6;
    color: #68c39d;
  }

  .bg_yellow {
    background-color: #ffdd9b;
    color: #ef9f05;
  }

  .bg_gray {
    background-color: #dfe4eb;
    color: #8a9099;
  }

  .activity_img {
    width: 100%;
    height: 146px;

    .image-slot {
      height: 100%;
    }

    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }

  .apply_btn {
    margin-top: 8px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
