<template>
  <PageWrapper :tabs="tabs" :tabActive.sync="tabActive" class="activity_wrapper">
    <PagePanel v-if="activityData.length > 0">
      <p class="title">{{ $t('activity.activityContent') }}</p>
      <StatusAlert type="warning" show-icon :closable="false">
        <div class="alert_description">
          Before making deposit,please ensure register the bonus via this link firstly
        </div>
      </StatusAlert>
      <keep-alive>
        <component :is="currentComponent" :data="activityData" />
      </keep-alive>
    </PagePanel>
    <Empty v-else class="empty_box" :image-size="200" :description="$t('activity.noActivity')">
    </Empty>
  </PageWrapper>
</template> 

<script>
import StatusAlert from '@/components/customUI/StatusAlert.vue'
import AllActivity from './components/AllActivity.vue'
import MyActivity from './components/MyActivity.vue'

export default {
  name: 'activity',
  components: {
    AllActivity,
    MyActivity,
    StatusAlert
  },
  data() {
    return {
      tabs: [
        { name: 'tab1', label: this.$t('activity.myActivity') },
        { name: 'tab2', label: this.$t('activity.allActivity') },
      ],
      tabActive: 'tab1',
      activityData: [
        { status: 0, banner: '', id: 1, apply: false },
        { status: 1, banner: 'https://maildiy.startrader.com/photo24.png', id: 3, apply: true },
        { status: 2, banner: 'https://maildiy.startrader.com/photo24.png', id: 4, apply: true },
        { status: 1, banner: 'https://maildiy.startrader.com/photo24.png', id: 6, apply: true },
        { status: 2, banner: 'https://maildiy.startrader.com/photo24.png', id: 7, apply: true },
        { status: 0, banner: 'https://maildiy.startrader.com/photo24.png', id: 8, apply: true },
      ]
    };
  },
  computed: {
    currentComponent() {
      return this.tabActive === 'tab1' ? AllActivity : MyActivity;
    }
  },
  methods: {

  },
};
</script>

<style lang="scss">
.activity_wrapper {
  .title {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .el-alert--warning {
    .el-alert__description {
      margin: 0;
    }
  }
}
</style>
